<template>
    <va-card title="Ping/Traceroute">
    <div>
        <div>
          <form>
          <div class="radio_fieldset">
            <fieldset class="radio_options">
            <va-radio-button
              option="Ping"
              v-model="form_data.config_type"
              :color="error_msg.option && 'danger'" />
            <va-radio-button
              option="Traceroute"
              v-model="form_data.config_type"
              :color="error_msg.option && 'danger'" />
            </fieldset>
          </div>
        <div v-if="form_data.config_type == 'Ping'">
            <va-input
              label="IP *"
              type="text"
              v-model="form_data.ip_addr"
              placeholder="Enter the ip for ping"
              :error="!!error_msg.ip_addr.length"
              :error-messages="error_msg.ip_addr" />
            <div class="d-flex justify--center mt-3">
              <va-button type="submit" class="my-0" @click.prevent="check()">Check</va-button>
            </div>
        </div>
        <div v-if="form_data.config_type == 'Traceroute'">
            <va-input
              label="IP *"
              type="text"
              v-model="form_data.ip_addr"
              placeholder="Enter the ip for traceroute"
              :error="!!error_msg.ip_addr.length"
              :error-messages="error_msg.ip_addr" />
            <div class="d-flex justify--center mt-3">
              <va-button type="submit" class="my-0" @click.prevent="check()">Check</va-button>
            </div>
        </div>
        <div class="flex xs12" v-if="output_flag">
              <span class="va-form-label va-form-required-label">Output</span> </br>             
              <textarea class="text_box" v-model="output" />
              <div class="d-flex justify--center mt-3">
                <va-button type="submit" class="my-0" @click="reset()">Ok</va-button>
            </div>
          </div>
        </form>
        </div>
    </div>
  </va-card>
</template>
<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'config',
  beforeCreate () {},
  data () {
    return {
      term: null,
      message: '',
      output: '',
      output_flag: false,
      form_data:{
        ip_addr: '',
        config_type: ''
      },
      error_msg:{
        option:true,
        ip_addr:[],
      },
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      showRemoveModal: false,
    }
  },
  computed: {

    formReady () {
      return !this.error_msg.ip_addr.length
    },
    fields () {},
    filteredData () {},
  },
  methods: {
    check() {
       this.error_msg.ip_addr = this.form_data.ip_addr ? [] : ['IP is required']
        
        if (!this.formReady) {
        return
      }
        if (this.ipFlag && !this.checkip) {
        Vue.notify({ text: 'Please IP correctly', type: 'error' })
        return
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'ping', this.form_data).then(responseData => {
        if (responseData && responseData.body) {
          loader.hide()
          this.output_flag = true;
          this.output = responseData.body;
          Vue.notify({
            text: "Command executed successfully",
            type: 'success',
          })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({
            text: "Command execution failed",
            type: 'error',
          })
        }
      })
    },
    add () {
      this.form_data.ip_addr.length = ''
    },
    reset(){
      this.error_msg.ip_addr = []
      this.output = ''
      this.output_flag = false;
      this.form_data.ip_addr = ''
      this.form_data.config_type=''
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style lang="scss">
.radio_options {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.text_box {
  width: 985px;
  height: 250px;
  border-color: black;
  border-radius: 10px;
  resize: none;
}

</style>
